import React from 'react'
import LogoDinastia from '../assets/images/logo-dinastia.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Footnote = () => {
  const { t } = useTranslation()

  return (
    <StyledFootnote className="footnote">
      <p>{t('footNote')}</p>
      <LogoDinastia />
    </StyledFootnote>
  )
}

export const StyledFootnote = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.white};

  p {
    font-size: 2rem;
    line-height: 2.9rem;
    letter-spacing: 0.12rem;
    margin-right: ${({ theme }) => theme.spacingS};
    text-transform: uppercase;
  }

  svg {
    width: 12rem;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    p {
      margin-right: 0;
    }
  }
`
