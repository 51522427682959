import React from 'react'
import styled from 'styled-components'

export const CTA = ({
  text,
  onClick,
  underline,
  blueBorder,
  greenBorder,
  className,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      underline={underline}
      blueBorder={blueBorder}
      greenBorder={greenBorder}
      className={`cta ${className}`}
    >
      {text}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  font-size: 1.8rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0));
  border: ${({ theme }) => `1px solid ${theme.green}`};
  padding: ${({ theme }) => `${theme.spacingXXXS} ${theme.spacingS}`};
  border-radius: 25rem;
  transition: all 1s ease;
  cursor: pointer;

  &:hover {
    background-image: ${({ theme }) =>
      `linear-gradient(to right, ${theme.green}, ${theme.purple}, ${theme.red})`};
    border: 1px solid transparent;
  }

  ${(props) =>
    props.underline &&
    `
    position: relative;
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background: none;
      border: none;
    }

    &:after {
      content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.6rem;
        height: 1px;
        background-image: linear-gradient(to right, ${props.theme.red}, ${props.theme.purple}, ${props.theme.green}, ${props.theme.greenTransparent});
    }
  `}

  ${(props) =>
    props.blueBorder &&
    `
    border: 1px solid ${props.theme.blue};
  `}

${(props) =>
    props.greenBorder &&
    `
    border: 1px solid ${props.theme.green};
  `}


  @media screen and (max-width: 1000px) {
    ${(props) =>
      props.underline &&
      `
    &:after {
     opacity: 0;
     transition: opacity .25s ease;
    }

    &:hover {
      background: none;
      border: none;

      &:after {
        opacity: 1;
      }
    }
  `}
  }
`
