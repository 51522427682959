export const theme = {
  spacingXXXS: '.5rem',
  spacingXXS: '1rem',
  spacingXS: '1.5rem',
  spacingS: '3rem',
  spacingM: '6rem',
  spacingL: '9rem',
  spacingXL: '15rem',
  fontFamily: '"Abel", sans-serif',
  fontFamilySecondary: '"Rubik", sans-serif',
  fontRegular: 400,
  fontMedium: 500,
  white: '#ffffff',
  black: '#000000',
  purple: '#3E418B',
  red: '#D80A1E',
  green: '#48A67F',
  blue: '#150A72',
  greenTransparent: 'rgba(72, 166, 127, 0)',
}
