import React from 'react'
import { SocialIcons } from './SocialIcons'
import { Footnote } from './Footnote'
import FooterLogo from '../assets/images/logo-footer.svg'
import InvisualLogo from '../assets/images/logo-invisual.svg'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Footer = () => {
  const { t } = useTranslation()
  const content = t('footer', { returnObjects: true })

  return (
    <StyledFooter>
      <div className="left">
        <Footnote />
      </div>

      <div className="center">
        <Link to="/">
          <FooterLogo />
        </Link>
      </div>

      <div className="right">
        <SocialIcons />
        <p className="uppercase">{content.copyright}</p>
        <p className="legal-pages">
          <Link to={content.privacyPageUrl}>{content.privacyPage}</Link> /{' '}
          <Link to={content.termsPageUrl}>{content.termsPage}</Link>
        </p>
        <p className="uppercase">
          <a href={content.complaintsBookUrl} target="_blank" rel="noopener">
            {' '}
            {content.complaintsBook}{' '}
          </a>
        </p>

        <div className="created-by">
          <p>{content.createdBy}</p>
          <a href={content.createdByUrl} target="_blank" rel="noopener">
            INVISUAL.PT
          </a>
        </div>
      </div>
    </StyledFooter>
  )
}

export const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacingL}`};
  border-top: 1px solid ${({ theme }) => theme.white};

  > div {
    width: 33.3%
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;

    p {
      font-size: 1.7rem;
      line-height: 3.8rem;
      letter-spacing: 0.05rem;
      
      &.uppercase {
        text-transform: uppercase;
      }
    }

    .
  }

  .footnote {
    flex-direction: column;
    align-items: flex-start;

    svg {
      width: 25rem;
      height: auto;
    }
  }

  .social-icons {
    justify-content: flex-end;
    svg {
      height: 2rem;
      width: auto;
      margin: 0 ${({ theme }) => theme.spacingXXS};

      &:last-child {
        margin-right: 0;
      }

      &.instagram,
      &.youtube {
        margin-right: 0.6rem;
      }
    }
  }

  .created-by {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacingM};

    p {
      margin-right: ${({ theme }) => theme.spacingXS};
    }

    a {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;

    .left, .center, .right {
      width: 100%;
    }

    .footnote {
      align-items: center;
    }

    .center {
      margin-top: ${({ theme }) => theme.spacingL};
    }

    .right {
      margin-top: ${({ theme }) => theme.spacingL};
      text-align: center;
    }

    .legal-pages a:nth-child(2) {
        display: block;
    }

    .social-icons {
      justify-content: center;
      margin-bottom: ${({ theme }) => theme.spacingS};
    }

    .created-by {
      flex-direction: column;
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacingS};

      p {
        margin: 0;
      }
    }
  }
`
