import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        // Remove the blue highlight everytime we click on something on mobile
        -webkit-tap-highlight-color: transparent;
    }

    html {
        font-size: 62.5%;
        scroll-behavior: smooth;
        webkit-text-size-adjust:none; 
    }

    body {
        font-family: ${({ theme }) => theme.fontFamily};
        background-color: ${({ theme }) => theme.black};
        color: ${({ theme }) => theme.white};
        overflow-x: hidden;
    }

    .fontSecondary {
        font-family: ${({ theme }) => theme.fontFamilySecondary};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    input, textarea, button {
        outline: none;
        background: transparent;
        border: none;
        color: ${({ theme }) => theme.white};
        font-family: ${({ theme }) => theme.fontFamily};
    }

    img {
        max-width: 100%;
    }

    .mbl {
        display: none;
    }

    .text-with-icon {
        position: relative;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-175%);
        }
    }

    .title {
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 5rem;
      line-height: 6rem;
      letter-spacing: 0.4rem;
      text-transform: uppercase;
    }

    .text {
        font-size: 3rem;
        line-height: 5rem;
        font-letter-spacing: 0.06rem;
    }

    .shape {
        position: absolute;
        z-index: 0;
    }

    .slick-slider {
        z-index: 111;
        cursor: grab;
    }

    section:not(.main-section){
        padding-left: ${({ theme }) => theme.spacingXL};
        padding-right: ${({ theme }) => theme.spacingXL};
        max-width: 175rem;
        margin: 0 auto;
    }

    @media screen and (max-width: 1400px) {
        section:not(.main-section){
            padding-left: ${({ theme }) => theme.spacingL};
            padding-right: ${({ theme }) => theme.spacingM};
        }
    }

    @media screen and (max-width: 1400px) {
        html {
            font-size: 56%;
        }
    }

    @media screen and (max-width: 1000px) {
        html {
            font-size: 54%;
        }

        .mbl {
            display: block;
        }
        .desktop {
            display: none !important;
        }

        section:not(.main-section){
            padding-left: ${({ theme }) => theme.spacingS};
            padding-right: ${({ theme }) => theme.spacingS};
        }

        .title {
            font-size: 4.5rem;
            line-height: 5.5rem;
        }

        .text {
            font-size: 2.4rem;
            line-height: 4.7rem;
            text-align: center;
        }

        .text-with-icon {
            text-align: center;

            .icon {
                margin-bottom: ${({ theme }) => theme.spacingS};
                position: unset;
                transform: none;
                text-align: center;

                svg {
                    width: auto;
                    height: 7rem;
                }
            }
        }
    }

    @media screen and (max-width: 350px) {
        html {
            font-size: 45%;
        }
    }
`
