import React from 'react'
import FacebookIcon from '../assets/images/facebook.svg'
import InstagramIcon from '../assets/images/instagram.svg'
import LinkedinIcon from '../assets/images/linkedin.svg'
import YoutubeIcon from '../assets/images/youtube.svg'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const SocialIcons = ({ isColumn }) => {
  const { t } = useTranslation()
  const content = t('social', { returnObjects: true })

  return (
    <StyledIcons className="social-icons" isColumn={isColumn}>
      <a href={content.facebook} target="_blank" rel="noopener">
        <FacebookIcon className="facebook" />
      </a>
      <a href={content.instagram} target="_blank" rel="noopener">
        <InstagramIcon className="instagram" />
      </a>
      <a href={content.youtube} target="_blank" rel="noopener">
        <YoutubeIcon className="youtube" />
      </a>
      <a href={content.linkedin} target="_blank" rel="noopener">
        <LinkedinIcon className="linkedin" />
      </a>
    </StyledIcons>
  )
}

export const StyledIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 ${({ theme }) => theme.spacingXS};

    path,
    rect {
      transition: fill 0.25s ease;
    }

    &.facebook:hover path {
      fill: ${({ theme }) => theme.purple};
    }
    &.instagram:hover path {
      fill: ${({ theme }) => theme.green};
    }
    &.youtube:hover path {
      fill: ${({ theme }) => theme.red};
    }
    &.linkedin:hover {
      path,
      rect {
        fill: ${({ theme }) => theme.purple};
      }
    }
  }

  ${(props) =>
    props.isColumn &&
    `
    flex-direction: column;
    gap: ${props.theme.spacingXXS};

    svg {
      height: 2.2rem;
      width: auto;
    }
  `}
`
