import React, { useState } from 'react'
import { CTA } from './CTA'
import { SocialIcons } from './SocialIcons'
import Logo from '../assets/images/logo.svg'
import Close from '../assets/images/close.svg'
import Menu from '../assets/images/menu.svg'
import styled from 'styled-components'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

export const Navbar = ({ isHome }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()
  const navContent = t('nav', { returnObjects: true })
  const navItems = isHome ? navContent.homeItems : navContent.items

  return (
    <>
      <StyledNavbar>
        <div className="container">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <div className="items desktop">
            {navItems &&
              navItems.length > 0 &&
              navItems.map((item) => (
                <Link key={item.url} to={item.url}>
                  {item.text}
                </Link>
              ))}
            <Link to={navContent.ctaUrl} className="cta-link">
              <CTA text={navContent.cta} className="desktop" />
            </Link>
          </div>

          <div className="items mbl" onClick={() => setIsOpen(!isOpen)}>
            <span>{isOpen ? navContent.close : navContent.menu}</span>
            {isOpen ? <Close /> : <Menu />}
          </div>
        </div>
      </StyledNavbar>

      {isOpen && (
        <StyleMobiledNavbar className="mbl-nav mbl">
          <div className="links">
            {navItems &&
              navItems.length > 0 &&
              navItems.map((item) => (
                <Link
                  key={item.url}
                  to={item.url}
                  onClick={() => setIsOpen(false)}
                >
                  {item.text}
                </Link>
              ))}
          </div>
          <Link to={navContent.ctaUrl} className="cta-link">
            <CTA text={navContent.cta} />
          </Link>
          <SocialIcons />
        </StyleMobiledNavbar>
      )}
    </>
  )
}

const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) =>
    `${theme.spacingS} ${theme.spacingS} ${theme.spacingS} ${theme.spacingM}`};
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1111;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo svg {
    width: 10rem;
    height: auto;
  }

  .items {
    transform: ${({ theme }) => `translateX(${theme.spacingM})`};
    a:not(.cta-link) {
      position: relative;
      font-size: 1.8rem;
      letter-spacing: 0.03rem;
      text-transform: uppercase;
      margin-right: ${({ theme }) => theme.spacingS};

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.6rem;
        height: 1px;
        background-image: ${({ theme }) =>
          `linear-gradient(to right, ${theme.red}, ${theme.purple}, ${theme.green}, ${theme.greenTransparent})`};
        opacity: 0;
        transform: translateY(1rem);
        transition: all 0.25s ease;
      }

      &:hover :after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .cta {
      padding-right: ${({ theme }) => theme.spacingM};
    }
  }

  @media screen and (max-width: 1000px) {
    padding: ${({ theme }) => `${theme.spacingS} ${theme.spacingM}`};

    .items {
      display: flex;
      align-items: center;
      transform: none;
      cursor: pointer;

      span {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-right: ${({ theme }) => theme.spacingS};
      }
    }
  }
`

const StyleMobiledNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacingXL};
  background: ${({ theme }) => theme.black};
  z-index: 111;

  .links {
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      position: relative;
      width: 100%;
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 3.5rem;
      margin: ${({ theme }) => `${theme.spacingS} 0`};
      text-align: center;
      text-transform: uppercase;

      &:after {
        content: '';
        width: 65%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -${({ theme }) => theme.spacingXS};
        background: ${({ theme }) => theme.white};
      }

      &:nth-child(even):after {
        left: unset;
        right: 0;
      }

      &:nth-child(3):after {
        bottom: -2.5rem;
      }
      &:nth-child(4):after {
        bottom: -4rem;
      }
    }
  }

  .cta-link {
    display: block;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .cta {
    background-image: ${({ theme }) =>
      `linear-gradient(to right, ${theme.green}, ${theme.purple}, ${theme.red})`};
  }

  .social-icons {
    margin-top: ${({ theme }) => theme.spacingXL};
  }
`

export const navbarQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
